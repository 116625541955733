<template>
  <svg viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.3539 9.15184C15.3539 14.7996 9.56924 18.279 8.3671 18.9418C8.30051 18.9785 8.22569 18.9978 8.14964 18.9978C8.07358 18.9978 7.99877 18.9785 7.93217 18.9418C6.72914 18.279 0.946289 14.7996 0.946289 9.15184C0.946289 4.64946 3.64771 1.4978 8.15009 1.4978C12.6525 1.4978 15.3539 4.64946 15.3539 9.15184Z" stroke="currentColor" stroke-width="1.47" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M4.54822 8.70126C4.54822 9.65655 4.9277 10.5727 5.60319 11.2482C6.27868 11.9237 7.19483 12.3032 8.15012 12.3032C9.1054 12.3032 10.0216 11.9237 10.697 11.2482C11.3725 10.5727 11.752 9.65655 11.752 8.70126C11.752 7.74598 11.3725 6.82982 10.697 6.15434C10.0216 5.47885 9.1054 5.09937 8.15012 5.09937C7.19483 5.09937 6.27868 5.47885 5.60319 6.15434C4.9277 6.82982 4.54822 7.74598 4.54822 8.70126V8.70126Z" stroke="currentColor" stroke-width="1.47" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: 'IconLocationSolid'
}
</script>
